import { Component, OnInit } from '@angular/core';
import { SearchDocumentService } from '../../../services/search-document.service';

interface ofDropdown {
  value: string;
  viewValue: Date;
}

@Component({
  selector: 'app-monthly-vechicle-statement',
  templateUrl: './monthly-vechicle-statement.component.html',
  styleUrls: ['./monthly-vechicle-statement.component.css']
})
export class MonthlyVechicleStatementComponent implements OnInit {
  dropdown: ofDropdown[] = [];
  JSONresponse: any;

  constructor(private searchDocument: SearchDocumentService) {
    this.searchDocument
      .searchDocuments({
        dealerNumber: JSON.parse(localStorage.getItem('userObject')).suzukiDealerNumber,
        documentType: {
          documentJobName: 'EDD_Vehicle_Statement'
        }
      })
      .subscribe(res => {
        console.log('EDD_Vehicle_Statement',res);
        this.JSONresponse = res;
        this.JSONresponse.forEach(obj => {
          this.dropdown.push({
            viewValue: new Date(obj.createDate),
            value:
              'https://d3einiiox7l0e3.cloudfront.net/edd/vehicle_statement/9ab648c9-16a6-4766-94a1-6c5cb9e9c3b5.pdf'
          });
        });
      });
  }

  ngOnInit() {}

  openWindow(select) {
    window.open(select, '_blank', 'top=50,left=50,width=600,heigh=500');
  }
}
