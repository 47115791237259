import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Login } from '../../store/actions/auth.actions';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  hide = true;
  loading = false;
  getState: Observable<any>;
  errorMessage: string | null;
  loginForm: FormGroup;
  show: boolean = false;
  buttonName: string = 'Dealers Click Here';

  constructor(private _store: Store<fromStore.State>) {
    this.getState = this._store.select('authState');
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      user_id: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
      dealer_no: new FormControl(null, [Validators.required])
    });

    this.getState.subscribe((state) => {
      this.loading = state.isLoading;
      this.errorMessage = state.errorMessage;
    });
  }

  onSubmit() {
    this.loading = true;
    this._store.dispatch(new Login(this.loginForm.value));

  }

  toggle() {
    this.show = !this.show;
    if (this.show) this.buttonName = 'SMAI Employees Click Here';
    else this.buttonName = 'Dealers Click Here';
  }
}
