import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-debit-and-credit-memos',
  templateUrl: './debit-and-credit-memos.component.html',
  styleUrls: ['./debit-and-credit-memos.component.css']
})
export class DebitAndCreditMemosComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private router: Router,

  ) { }

  partsInvoiceForm = this.fb.group({
    firstName: this.fb.control(''),
    lastName: this.fb.control(''),
  });

  ngOnInit() {
  }
  onSubmit(){
    this.router.navigate(['office/electronic-document-delivery/memoSearchResults']);
  }

}
