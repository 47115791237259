import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchDocumentService {

  constructor( private http: HttpClient,) { 
    
  }

  public searchDocuments(info : any) {
    console.log(info);
    return this.http.post(`${environment.searchDocument}`, info );
  }
}
