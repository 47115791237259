import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchDocumentService } from '../../../services/search-document.service';


@Component({
  selector: 'app-parts-invoice',
  templateUrl: './parts-invoice.component.html',
  styleUrls: ['./parts-invoice.component.css']
})
export class PartsInvoiceComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private router: Router,

  ) { }

  partsInvoiceForm = this.fb.group({
    firstName: this.fb.control(''),
    lastName: this.fb.control(''),
  });

  ngOnInit() {
  }
  onSubmit(){
    this.router.navigate(['office/electronic-document-delivery/invoiceSearchResults']);
  }

}
