import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import { Logout } from '../../store/actions/auth.actions';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  firstName: string;
  lastName: string;

  constructor(
    private _store: Store<fromStore.State>
    ) {}

  ngOnInit() {
    var userObject = JSON.parse(localStorage.getItem('userObject'));
      this.firstName = userObject.firstName;
      this.lastName = userObject.lastName;
  }
  onLogout() {
    this._store.dispatch(new Logout());
  }
}