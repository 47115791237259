import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumService {
  constructor() {}

  getMenu(): Array<any> {
    const menu = [
      { name: 'Home', path: '/home', children: [] },
      {
        name: 'Office',
        path: '/office',
        children: [
          {
            name: 'Electronic Document Delivery',
            path: '/electronic-document-delivery',
            children :[
              {
                name: 'Monthly Parts Statement',
                path: '/MonthlyPatsStatement'
              },
              {
                name: 'Debit and Credit Memos',
                path: '/memos_search'
              },
              {
                name: 'Monthly Vechicle Statement',
                path: '/monthly-vechicle-statement'
              },
              {
                name: 'Parts Invoice',
                path: '/partsInvoice'
              },
              {
                name: 'Debit & Credit Memos',
                path: '/debit&CreditMemos'
              },

              {
                name: 'Invoice Search Results',
                path: '/invoiceSearchResults'
              }
            ]
          }
        ]
      }

    ];

    return menu;
  }
}
