import { AuthActionTypes, AuthActions } from '../actions/auth.actions';
//login state interface
export interface LoginState {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: object;
  errorMessage: string | null;
}

//set the initial state with localStorage
export const initialState: LoginState = {
  isAuthenticated: false,
  isLoading : false,
  user: null,
  errorMessage: null
};

// reducer function for auth
export function reducer(state = initialState, action: AuthActions): LoginState {
  
  switch (action.type) {
    case AuthActionTypes.Login: {
      return {
        ...state,
        isAuthenticated: true,
        errorMessage: null,
        isLoading : true
      };
    }
    case AuthActionTypes.LoginSuccess: {
      return {
        ...state,
        isAuthenticated: true,
        //user: null,
        errorMessage: null,
        isLoading : false
      };
    }
    case AuthActionTypes.LoginFailure: {
      return {
        ...state,
        errorMessage: 'Login failed. Please enter a valid user ID and password',
        isLoading : false
      };
    }
    case AuthActionTypes.Logout: {
      return initialState;
    }
    case AuthActionTypes.Token: {
      return {
        ...state,
        isAuthenticated: true,
        user: action.apiResponse,
        errorMessage: null,
        isLoading : true
      };
    }
    default: {
      return state;
    }
  }
}
