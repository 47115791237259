import { Action } from '@ngrx/store';
import { Authenticate } from '../../model/Authenticate.model';

export enum AuthActionTypes {
  Login = 'Login',
  LoginSuccess = 'Login Success',
  LoginFailure = 'Login Failure',
  Logout = 'Logout',
  Token = 'Token'
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;

  constructor(public credentials: Authenticate) {
     //console.log(payload);
  }
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;
  constructor(public token : any){}
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;

  constructor(public error : object) {
    console.log("login failed", error);
  }
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class Token implements Action {
  readonly type = AuthActionTypes.Token;
  constructor(public apiResponse, public credentials){}
}

export type AuthActions = Login | LoginSuccess | LoginFailure | Logout | Token;