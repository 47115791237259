import { Component, OnInit } from '@angular/core';
import { BreadcrumService } from '../../services/breadcrum.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrum',
  templateUrl: './breadcrum.component.html',
  styleUrls: ['./breadcrum.component.css']
})
export class BreadcrumComponent implements OnInit {

  name: string;
  menu: Array<any> = [];
  breadcrumbList: Array<any> = [];

  constructor(
    private _router: Router,
    private breadcrumService: BreadcrumService
  ) {
    this.menu = this.breadcrumService.getMenu();
    this.listenRouting();
  }

  ngOnInit() {}

  listenRouting() {
    let routerUrl: string, routerList: Array<any>, target: any;
    this._router.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        target = this.menu;
        this.breadcrumbList.length = 0;

        routerList = routerUrl.slice(1).split('/');
        routerList.forEach((router, index) => {
          target = target.find(page => page.path.slice(1) === router);
         // console.log(target);
          this.breadcrumbList.push({
            
            name: target.name,

            path:
              index === 0
                ? target.path
                : `${this.breadcrumbList[index - 1].path}/${target.path.slice(
                    1
                  )}`
          });
         // console.log(this.breadcrumbList);
          if (index + 1 !== routerList.length) {
            target = target.children;
          }
        });
      }
    });
  }
}
