import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  public add(key, value) {
      value = JSON.stringify(value)
      sessionStorage.setItem(key, value);
  }
  public get(key) {
    let value: any = sessionStorage.getItem(key);
    if (value) {
       value = JSON.parse(value);
    }
    return value;
  }
  public remove(key) {
    sessionStorage.removeItem(key);
  }

  loggedIn(){
    return !!sessionStorage.getItem('authToken')
  }
}
