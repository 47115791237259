import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Params } from '@angular/router';
import * as fromRouter from '@ngrx/router-store';
import { environment } from '../../environments/environment';
import * as auth from './reducers/auth.reducer';
import { Injectable } from "@angular/core";

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params : Params
}

export interface State {
  authState: auth.LoginState;
  routerReducer : fromRouter.RouterReducerState<RouterStateUrl>
}


export const reducers: ActionReducerMap<State> = {
  authState: auth.reducer,
  routerReducer : fromRouter.routerReducer,
}

export const getRouterState = createFeatureSelector<
    fromRouter.RouterReducerState<RouterStateUrl>
>('routerReducer');

@Injectable()
export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl>{
  serialize(routerState : RouterStateSnapshot):RouterStateUrl{
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state : ActivatedRouteSnapshot = routerState.root;
    while(state.firstChild){
      state = state.firstChild;
    }
    const { params } = state;
    return { url, queryParams , params };
  }
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? []
  : [];
