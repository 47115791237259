import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ElectronicDocumentDeliveryComponent } from './electronic-document-delivery/electronic-document-delivery.component';
import { DebitAndCreditMemosComponent } from './SearchForms/debit-and-credit-memos/debit-and-credit-memos.component';
import { PartsInvoiceComponent } from './SearchForms/parts-invoice/parts-invoice.component';
import { InvoiceSearchResultsComponent } from './invoice-search-results/invoice-search-results.component';
import { OfficeHomeComponent } from './office-home/office-home.component';
import { AuthGuard } from '../services/auth.guard';
import { MonthlyVechicleStatementComponent } from './SearchForms/monthly-vechicle-statement/monthly-vechicle-statement.component';
import { MemoSearchResultsComponent } from './memo-search-results/memo-search-results.component';
import { MonthlyPartsStatementComponent } from './SearchForms/monthly-parts-statement/monthly-parts-statement.component';

const routes: Routes = [

  {path: '', component: OfficeHomeComponent},
  
  { path: 'electronic-document-delivery', data: { breadcrumb: ['Electric Document Delivery'] }, component: ElectronicDocumentDeliveryComponent , children:[
    { path: 'memos_search', component: DebitAndCreditMemosComponent},
    { path: 'partsInvoice', component: PartsInvoiceComponent},
    { path: 'invoiceSearchResults', component: InvoiceSearchResultsComponent},
    { path: 'memoSearchResults', component: MemoSearchResultsComponent},
    {path : 'monthly-vechicle-statement', component: MonthlyVechicleStatementComponent },
    {path : 'MonthlyPatsStatement', component: MonthlyPartsStatementComponent }

  ]},





];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfficeRoutingModule {}
