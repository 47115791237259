import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

// import {
//   CognitoUserPool,
//   CognitoUserAttribute,
//   CognitoUser,
//   AuthenticationDetails
// } from 'amazon-cognito-identity-js';
// const AWS = require('aws-sdk');
// require('amazon-cognito-js');
//import * as AWS from 'amazon-cognito-js';

export interface InvoiceList {
  invoice: number;
  order_number: string;
  invoice_process_date: string;
  amount: number;
}

const DATA : InvoiceList[] = [
  {invoice : 14211775 , order_number: '0013515' , invoice_process_date : '04/06/2018', amount : 254.58},
  {invoice : 14211776 , order_number: '0013515' , invoice_process_date : '04/06/2018', amount : 26.70},
  { invoice : 14211775 , order_number: '0013515' , invoice_process_date : '04/06/2018' , amount : 254.58 },

{ invoice : 14211776 , order_number: '0013515' , invoice_process_date :'04/06/2018'  , amount : 26.70},

{ invoice : 14212087 , order_number: 'BOR0402' , invoice_process_date :'04/06/2018'  , amount : 2.05},

{ invoice : 14214913 , order_number: '0013527' , invoice_process_date : '04/06/2018' , amount : 456.34},

{ invoice : 14150761 , order_number: 'R100076' , invoice_process_date : '01/13/2018' , amount : -62.76},

{ invoice : 14151346 , order_number: 'BOR0108' , invoice_process_date :'01/13/2018' , amount : 97.29},

{ invoice : 14151783 , order_number: '0013140' , invoice_process_date : '01/13/2018' , amount : 159.08},

{ invoice : 14153547 , order_number: '0013150' , invoice_process_date : '01/13/2018' , amount : 120.30},

{ invoice : 14153548 , order_number: '0013150' , invoice_process_date : '01/13/2018' , amount : 57.95},

{ invoice : 14153549 , order_number: '0013150' , invoice_process_date : '01/13/2018' , amount : 39.5},

{ invoice : 14154289 , order_number: 'MWBOR1'  , invoice_process_date :'01/13/2018' , amount : 12.09},

{ invoice : 14154290 , order_number: 'MWBOR2'  , invoice_process_date :'01/13/2018' , amount : 12.09},

{ invoice : 14063579 , order_number: '0012540' , invoice_process_date :'09/15/2017' , amount : 89.74},

{ invoice : 14069324 , order_number: '0012568' , invoice_process_date :'09/15/2017' , amount : 127.66},

{ invoice : 14069325 , order_number: '0012568' , invoice_process_date :'09/15/2017' , amount : 43.33},

{ invoice : 14070439 , order_number: '0012576' , invoice_process_date : '09/15/2017' , amount : 150.72},

{ invoice : 14070440 , order_number: '0012576' , invoice_process_date : '09/15/2017' , amount : 50.00},

{ invoice : 14071526 , order_number: '0012586' , invoice_process_date : '09/15/2017' , amount : 783.27},

   { invoice : 14071527 , order_number: '0012586' , invoice_process_date : '09/15/2017' , amount : 0.94}
]


@Component({
  selector: 'app-invoice-search-results',
  templateUrl: './invoice-search-results.component.html',
  styleUrls: ['./invoice-search-results.component.css']
})
export class InvoiceSearchResultsComponent implements OnInit {
  displayedColumns: string[] = ['select','invoice', 'order_number', 'invoice_process_date', 'amount'];
  dataSource: MatTableDataSource<InvoiceList>;
  selection = new SelectionModel<InvoiceList>(true, []);

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor() {
    this.dataSource = new MatTableDataSource(DATA);
   // this.cognitoFunc();
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  getRecord(element) {
    console.log(element);
  }

   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(row => this.selection.select(row));
    }

      /** The label for the checkbox on the passed row */
  checkboxLabel(row?: InvoiceList): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.invoice + 1}`;
  }

  // cognitoFunc() {

  //   var token = JSON.parse(localStorage.getItem('myIdToken')).jwtToken;
  //   AWS.config.region = 'us-west-2';
  //   AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  //     IdentityPoolId: 'us-west-2:88bbbd7b-8bb1-4cb0-bf95-b653c8a23a27',
  //     Logins: {
  //       'cognito-idp.us-west-2.amazonaws.com/us-west-2_QEQN0bh2G': token
  //     }
  //   });

  //   AWS.config.credentials.get(function () {

  //     var client = new AWS.CognitoSyncManager();

  //     client.openOrCreateDataset('all_my_documents', function (err, dataset) {

  //       dataset.synchronize({
  //         onSuccess: function (dataset, newRecords) {
  //           console.log(dataset, newRecords);
  //         },
  //         onFailure: function (err) {
  //           return err;
  //         },
  //       });

  //       dataset.get('document_id', function (err, value) {
  //         console.log(value);
  //         if (typeof (value) != "undefined") {
  //           var my_output = JSON.parse(value)
  //           var get_length = Object.keys(my_output).length;
  //           for (var i = 0; i < get_length; i++) {
  //             if (my_output[i] === 'abcd1234') {
  //               console.log("Valid Document");
  //             } else {
  //               console.log("Not authrised user")
  //             }
  //           }
  //           return "Invalid Document";
  //         }
  //         else {
  //           return "Invalid Document"
  //         }
  //       });
  //     });
  //   });
  // }
}
