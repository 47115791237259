import { Component, OnInit } from '@angular/core';

export interface mail {
  Date: string;
  icon:string;
  Message: string;
  Dept: string;
  Type: string;
  Topic: string;
}

const ELEMENT_DATA: mail[] = [
  { Date: '11/18/2009', icon :'',Message: 'MotorcycleFullDirectoryIndex', Dept: 'All', Type: '	Bulletin', Topic: '' },
  { Date: '06/21/2013', icon :'',Message: 'Programs Acknowledgment Form MC ATV', Dept: 'Office', Type: '	Bulletin', Topic: '' }
];

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  constructor() {}

  firstName: string;
  lastName: string;
  suzukiPortalUID: string;
  suzukiDealerNumber: string;

  ngOnInit() {
    var userObject = JSON.parse(localStorage.getItem('userObject'));
    this.firstName = userObject.firstName;
    this.lastName = userObject.lastName;
    this.suzukiPortalUID = userObject.suzukiPortalUID;
    this.suzukiDealerNumber = userObject.suzukiDealerNumber;
  }

  displayedColumns: string[] = ['Date', 'icon','Message','Dept','Type','Topic'];
  dataSource = ELEMENT_DATA;

  folders = [
    {
      name: 'MotorcycleFullDirectoryIndex',
      subject: '...click title. Message displayed until 12/18/2020.'
    },
    {
      name: 'Programs Acknowledgment Form MC ATV',
      subject: '...click title. Message displayed until 12/18/2020.'
    }
  ];
}
