import { Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';


interface NavItem {
  name: string;
  icon?: string;
  route?: string;
  children?: NavItem[];
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  isMatMenu3Open = false;
  isMatMenu4Open = false;
  prevButtonTrigger;

  constructor(private ren: Renderer2) {}

  ngOnInit() {}

  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger, button) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
        this.ren.removeClass(
          button['_elementRef'].nativeElement,
          'cdk-focused'
        );
        this.ren.removeClass(
          button['_elementRef'].nativeElement,
          'cdk-program-focused'
        );
      } else {
        this.isMatMenuOpen = false;
      }
    }, 80);
  }

  menu2enter() {
    this.isMatMenu2Open = true;
    if (this.isMatMenu3Open) {
      this.isMatMenu3Open = false;
    }
  }

  menu2Leave(trigger, trigger2, button) {
    setTimeout(() => {
      if (this.isMatMenu2Open && !this.isMatMenu3Open) {
        trigger.closeMenu();
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        this.enteredButton = false;
        this.ren.removeClass(
          button['_elementRef'].nativeElement,
          'cdk-focused'
        );
        this.ren.removeClass(
          button['_elementRef'].nativeElement,
          'cdk-program-focused'
        );
      } else if (this.isMatMenuOpen && !this.isMatMenu2Open) {
        this.isMatMenu2Open = false;
        trigger2.closeMenu();
      } else {
        this.isMatMenu2Open = false;
      }
    }, 100);
  }

  menu3enter() {
    console.log('entered in menu3');
    this.isMatMenu3Open = true;
    if (this.isMatMenu4Open) {
      this.isMatMenu4Open = false;
    }
  }

  menu3Leave(trigger, trigger2, trigger3, button) {
    setTimeout(() => {
      if (!this.isMatMenu3Open) {
        trigger3.closeMenu();

        this.isMatMenu3Open = false;

        this.ren.removeClass(
          button['_elementRef'].nativeElement,
          'cdk-focused'
        );
        this.ren.removeClass(
          button['_elementRef'].nativeElement,
          'cdk-program-focused'
        );
      } else if (!this.isMatMenu2Open) {
        trigger.closeMenu();

        this.isMatMenu3Open = false;
        this.enteredButton = false;
        this.ren.removeClass(
          button['_elementRef'].nativeElement,
          'cdk-focused'
        );
        this.ren.removeClass(
          button['_elementRef'].nativeElement,
          'cdk-program-focused'
        );
      }
    }, 100);
  }

  buttonEnter(trigger) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
      } else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
      } else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
      }
    });
  }

  buttonLeave(trigger, button) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(
          button['_elementRef'].nativeElement,
          'cdk-focused'
        );
        this.ren.removeClass(
          button['_elementRef'].nativeElement,
          'cdk-program-focused'
        );
      }
      if (!this.isMatMenuOpen) {
        trigger.closeMenu();
        this.ren.removeClass(
          button['_elementRef'].nativeElement,
          'cdk-focused'
        );
        this.ren.removeClass(
          button['_elementRef'].nativeElement,
          'cdk-program-focused'
        );
      } else {
        this.enteredButton = false;
      }
    }, 100);
  }

  navItems: NavItem[] = [
    {
      name: 'Home',
      icon: 'close',
      route: '',
      children: []
    },
    {
      name: 'Parts',
      icon: 'close',
      children: [
        {
          name: 'Parts & Accessories Availability',
          icon: 'group'
        },
        {
          name: 'Create Orders',
          children: [
            {
              name: 'Parts Order Entry',
              icon: 'star_rate',
              route: 'material-design'
            },
            {
              name: 'Parts Order Change/Delete',
              icon: 'star_rate',
              route: 'what-up-web'
            },
            {
              name: 'DMS Parts Orders',
              icon: 'star_rate',
              route: 'my-ally-cli'
            }
          ]
        },
        {
          name: 'Order Status',
          icon: 'feedback',
          children: [
            {
              name: 'Order Inquiry',
              icon: 'star_rate',
              route: 'material-design'
            },
            {
              name: 'Back Orders',
              icon: 'star_rate',
              route: 'what-up-web'
            }
          ]
        },
        {
          name: 'EPC',
          icon: 'feedback',
          children: []
        },
        {
          name: 'Parts & Accessories Return',
          icon: 'feedback',
          children: [
            {
              name: 'Monthly Return',
              icon: 'star_rate',
              route: 'material-design'
            },
            {
              name: 'Discrepancy Report',
              icon: 'star_rate',
              route: 'what-up-web'
            }
          ]
        },
        {
          name: 'Parts to Model Reference',
          icon: 'feedback',
          children: []
        },
        {
          name: 'Publications',
          icon: 'feedback',
          children: [
            {
              name: 'Parts Bulletins & Forms',
              icon: 'star_rate',
              route: 'material-design'
            },
            {
              name: 'Safety Data Sheet (SDS)',
              icon: 'star_rate',
              route: 'what-up-web'
            },
            {
              name: 'Parts Policies',
              icon: 'star_rate',
              route: 'material-design'
            },
            {
              name: 'Accessories Bulletins and Installation Instructions',
              icon: 'star_rate',
              route: 'what-up-web'
            },
            {
              name: 'Accessories Catalog',
              icon: 'star_rate',
              route: 'what-up-web'
            }
          ]
        },
        {
          name: 'E-Mail Parts Coordinator',
          icon: 'feedback',
          children: []
        }
      ]
    },
    {
      name: 'Sales',
      icon: 'close',
      children: [
        {
          name: 'Create Order',
          icon: 'group',
          children: [
            {
              name: 'Vehicle Order Entry',
              icon: 'person',
              route: 'michael-prentice'
            },
            {
              name: 'Order Status Inquiry',
              icon: 'person',
              route: 'stephen-fluin'
            }
          ]
        },
        {
          name: 'Registration',
          icon: 'speaker_notes',
          children: [
            {
              name: 'Register Vehicle',
              icon: 'star_rate',
              route: 'material-design'
            },
            {
              name: 'Registration Change/Cancel',
              icon: 'star_rate',
              route: 'what-up-web'
            },
            {
              name: 'DMS Registration',
              icon: 'star_rate',
              route: 'my-ally-cli'
            },
            {
              name: 'Sales Person Administration',
              icon: 'star_rate',
              route: 'become-angular-tailer'
            }
          ]
        },
        {
          name: 'Layaway',
          icon: 'feedback',
          children: [
            {
              name: 'Layaway Entry',
              icon: 'star_rate',
              route: 'material-design'
            },
            {
              name: 'Layaway Change/Cancel',
              icon: 'star_rate',
              route: 'what-up-web'
            }
          ]
        },
        {
          name: 'Manage Vehicles',
          icon: 'speaker_notes',
          children: [
            {
              name: 'Change Ownership',
              icon: 'star_rate',
              route: 'material-design'
            },
            {
              name: 'Transfer from Dealer',
              icon: 'star_rate',
              route: 'what-up-web'
            }
          ]
        },
        {
          name: 'Vehicle Locator',
          icon: 'feedback',
          route: 'feedback'
        },
        {
          name: 'View Order Status',
          icon: 'feedback',
          route: 'feedback'
        },
        {
          name: 'Dealership Inventory',
          route: ''
        },
        {
          name: 'Vehicle Master Inquiry',
          route: ''
        },
        {
          name: 'Vehicle Status Dashboard',
          route: ''
        },
        {
          name: 'Vehicle Distribution',
          route: ''
        },
        {
          name: 'Sales Inquiry',
          route: ''
        },
        {
          name: 'Reports',
          children: [
            {
              name: 'Yearly Sales Summary Report',
              route: ''
            }
          ]
        },
        {
          name: 'Publications',
          children: [
            {
              name: 'Sales and Finance Bulletins',
              route: ''
            }
          ]
        }
      ]
    },
    {
      name: 'Service',
      icon: 'close',
      children: [
        {
          name: 'Warranty Claims',
          children: [
            {
              name: 'Claim Entry',
              children: [
                {
                  name: 'Regular'
                },
                {
                  name: 'Concealed Crate Damage'
                },
                {
                  name: 'Short Campaign'
                },
                {
                  name: 'Long Campaign'
                }
              ]
            },
            {
              name: 'Delete Claim'
            },
            {
              name: 'Claim Status'
            },
            {
              name: 'Claim Inquiry'
            },
            {
              name: 'Copy Claim To Resubmit'
            },
            {
              name: 'Labor Operation Lookup'
            }
          ]
        },
        {
          name: 'Vehicle Master Inquiry'
        },
        {
          name: 'Campaign Service Status Report'
        },
        {
          name: 'Parts Availability'
        },
        {
          name: 'Publications',
          children: [
            {
              name: 'Bulletins and Newsletters'
            },
            {
              name: 'Safety Data Sheet (SDS)'
            },
            {
              name: 'Quick Claim Guide'
            },
            {
              name: 'Warranty Policy Manual'
            },
            {
              name: 'Warranty Procedure Guide'
            },
            {
              name: 'On-Road MC Warranty Policy Booklet'
            },
            {
              name: 'Off-Road MC Warranty Policy Booklet'
            },
            {
              name: 'ATV Warranty Policy Booklet'
            },
            {
              name: 'Technical Manuals & Assembly Bulletins'
            },
            {
              name: 'Administrative Materials'
            }
          ]
        },
        {
          name: 'SEP Claims Information'
        },
        {
          name: 'Ask Our Tech-line Staff'
        },
        {
          name: 'SDS Access Key'
        }
      ]
    },
    {
      name: 'Office',
      icon: 'close',
      children: [
        {
          name: 'eBinder',
          icon: 'group',
          children: [
            {
              name: '2020',
              route: ''
            },
            {
              name: '2019',
              route: ''
            },
            {
              name: '2018',
              route: ''
            }
          ]
        },
        {
          name: 'Vehichle Price List',
          icon: 'speaker_notes',
          children: [
            {
              name: '2020',
              route: ''
            },
            {
              name: '2019',
              route: ''
            },
            {
              name: '2018',
              route: ''
            },
            {
              name: '2017',
              route: ''
            }
          ]
        },
        {
          name: 'Payment',
          icon: 'feedback'
        },
        {
          name: 'Parts Payment History',
          icon: 'feedback'
        },
        {
          name: 'ACH Receipt',
          icon: 'feedback'
        },
        {
          name: 'Electronic Document Delivery',
          icon: 'feedback',
          route: '/office/electronic-document-delivery'
        },
        {
          name: 'Corporate Policies/Requirements',
          icon: 'feedback',
          children: [
            {
              name: 'Powersports Dealer Advertising Policy',
              route: ''
            },
            {
              name: 'ATV Safety',
              children: [
                {
                  name: 'ATV Rider Age Recommendation Policy',
                  route: ''
                },
                {
                  name:
                    'ATV Point-Of-Purchase Safety Materials (Labels & Hangtags)',
                  route: ''
                },
                {
                  name: 'ATV Rider Training Certificate',
                  route: ''
                },
                {
                  name: 'ATV Safety Alert',
                  route: ''
                },
                {
                  name: 'A Message to Parents About ATV Safety',
                  route: ''
                }
              ]
            },
            {
              name: 'Dealer Development',
              children: [
                {
                  name: 'Dealer Development Requirements MC ATV',
                  route: ''
                },
                {
                  name: 'Dealer Development Requirements ATV',
                  route: ''
                }
              ]
            },
            {
              name: 'Program Acknowledgement Form MC-ATV',
              route: ''
            },
            {
              name: 'Credit Department Policies',
              route: ''
            },
            {
              name: 'Delivered with Pride Manual',
              route: ''
            },
            {
              name: 'Powersports Sales Policy',
              route: ''
            },
            {
              name: 'Supplement to Powersports Sales Policy',
              route: ''
            },
            {
              name: 'Recall Policies',
              children: [
                {
                  name: 'Safety Recall Repairs -Sales Policy',
                  route: ''
                },
                {
                  name: 'Safety Recall Repairs Prior to Unit Sale Policy',
                  route: ''
                }
              ]
            },
            {
              name: 'Service Operations Guide',
              route: ''
            },
            {
              name: 'Warranty Policy Manual',
              route: ''
            },
            {
              name: 'Logistics Policies and Procedures',
              children: [
                {
                  name: 'Procedures for ordering duplicate MSO/MCO',
                  route: ''
                }
              ]
            }
          ]
        },
        {
          name: 'Custom Showroom Branding Product',
          icon: 'feedback'
        }
      ]
    },
    {
      name: 'Admin',
      children: [
        {
          name: 'Suzuki Connect Requirements',
          icon: 'feedback'
        },
        {
          name: 'Edit My Profile',
          icon: 'feedback'
        },
        {
          name: 'Check My Environment',
          icon: 'feedback'
        },
        {
          name: 'User Administration',
          icon: 'feedback'
        }
      ]
    },
    {
      name: 'Communications',
      children: [
        {
          name: 'Inbox',
          icon: 'feedback'
        },
        {
          name: 'Inbox Review',
          icon: 'feedback'
        }
      ]
    },
    {
      name: 'Training',
      children: [
        {
          name: 'Suzuki Connect',
          children: [
            {
              name: 'Overview for Dealer Employees',
              icon: 'feedback'
            },
            {
              name: 'Registering Vehicles',
              icon: 'feedback'
            },
            {
              name: 'Managing Sales',
              icon: 'feedback'
            },
            {
              name: 'Dealer Administrator',
              icon: 'feedback'
            },
            {
              name: 'How to use Vehicle Order Entry',
              icon: 'feedback'
            }
          ]
        },
        {
          name: 'Direct Access to ServicePRO',
          icon: 'feedback'
        }
      ]
    }
  ];
}
