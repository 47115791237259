import { Action, Store } from '@ngrx/store';
import { Injectable, Pipe } from '@angular/core';
import { Actions, Effect, ofType, createEffect } from '@ngrx/effects';

import {
  map,
  exhaustMap,
  catchError,
  tap,
  mergeMap,
  switchMap,
  flatMap,
  concatMap
} from 'rxjs/operators';
import { of, from, Observable } from 'rxjs';
import {
  AuthActions,
  AuthActionTypes,
  Login,
  LoginSuccess,
  LoginFailure,
  Logout,
  Token,
  //Token
} from '../actions/auth.actions';
import { Router } from '@angular/router';
import { CurrentTokenService } from '../../services/core/currentToken/current-token.service';
import { AuthService } from '../../services/Auth-Service/auth.service';
import { State } from '..';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private _authService: AuthService,
    private router: Router,
    private _store: Store<State>,
    private _currentTokenService: CurrentTokenService,
    // private AuthActions : AuthActions
  ) { }




  login$ = createEffect(() =>
    this.actions$.pipe(
      // Filters by Action Creator 'login'
      ofType<Login>(AuthActionTypes.Login),
      exhaustMap(action =>
        this._authService.getUserDetails(`${action.credentials.dealer_no + action.credentials.user_id}`).pipe(
          map(userDetail => {
           // console.log(userDetail);
            localStorage.setItem('userObject', JSON.stringify(userDetail.body));
            return new Token(userDetail.body, action.credentials)
          }),
          catchError(error => of(new LoginFailure(error)))
        )
      )
    ), { resubscribeOnError: false }
  );


  getToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<Token>(AuthActionTypes.Token),
      exhaustMap(action =>
        this._authService
          .getToken(action.apiResponse.uid, action.apiResponse.userPassword).pipe(
            map((res:HttpResponse<any>) => {
             //console.log(res);
              localStorage.setItem('myIdToken', JSON.stringify(res.body.body.idToken));
              return new LoginSuccess(res)
            }),
            catchError(err => of(new LoginFailure(err)))
          )
      )
    ),
    { resubscribeOnError: false, dispatch: true }
  );

  
  @Effect({ dispatch: false })
  loginRedirect$ = this.actions$.pipe(
    ofType<LoginSuccess>(AuthActionTypes.LoginSuccess),
    tap((token: any) => {
      //let token = res.body;
      //let t = JSON.parse(token);
      //console.log(t.body);
      this._currentTokenService.setToken(token);
      this.router.navigate(['/home']);
    })
  );

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    tap(() => {
      // console.log('in logout effect');
      this._currentTokenService.deleteToken();
      this.router.navigate(['/login']);
    })
  );
}
