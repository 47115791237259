import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AcessoriesAvailabilityComponent } from './acessories-availability/acessories-availability.component';






const routes: Routes = [

  {   path: 'accessories', component: AcessoriesAvailabilityComponent  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PartsRoutingModule {}
