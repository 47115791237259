import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import {
  StoreRouterConnectingModule,
  RouterStateSerializer
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PartsModule } from './parts/parts.module';
import { OfficeModule } from './office/office.module';

import { MaterialModule } from './material.module';

import { FooterComponent } from './common/footer/footer.component';
import { LoginComponent } from './common/login/login.component';
import { reducers, metaReducers, CustomSerializer } from './store';
import { AuthEffects } from './store/effects/auth.effects';
import { environment } from '../environments/environment';
import { MainLayoutComponent } from './common/MainLayout/MainLayout.component';
import { SharedModule } from './shared/shared.module';
import { AuthService } from './services/Auth-Service/auth.service';
import { LandingPageComponent } from './common/landing-page/landing-page.component';
import { BreadcrumComponent } from './common/breadcrum/breadcrum.component';

@NgModule({
  declarations: [
    AppComponent, 
    LoginComponent, 
    MainLayoutComponent, 
    LandingPageComponent,
    BreadcrumComponent
  ],
  
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    PartsModule,
    OfficeModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    EffectsModule.forRoot([AuthEffects]),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    StoreRouterConnectingModule.forRoot()
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
