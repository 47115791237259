import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CurrentTokenService } from '../core/currentToken/current-token.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(
    private http: HttpClient,
    private _currentTokenService: CurrentTokenService
    ) { }

  public getToken(username:string,dealerID:string ) {
    let pass = encodeURIComponent(dealerID);
    return this.http.get(`${environment.tokenUrl}?my_user=${username}&my_pass=${pass}`,{observe : 'response'});
  }

  // public checkRole() {
  //   return this.http.get<serverResponse>(`${environment.baseUrl + "user/"}`, {
  //     observe: "response"
  //   });
  // }

  /**
   * getUserDetails method for user data
   */
  public getUserDetails(uid: string) {
    console.log("uid from service page",uid);
    return this.http.get(`${environment.baseUrl}?uid=${uid}`, {
      observe: 'response'
    });
  }
}
