import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './common/login/login.component';
import { AuthGuard } from './services/auth.guard';
import { MainLayoutComponent } from './common/MainLayout/MainLayout.component';
import { LandingPageComponent } from './common/landing-page/landing-page.component';

const routes: Routes = [
   { path: '', redirectTo: '/home',  pathMatch: 'full' },

  { path: 'login', component: LoginComponent },
  
  { path: '', canActivate:[AuthGuard],  component: MainLayoutComponent , children:[
    {path:'home', component: LandingPageComponent, data: { breadcrumb: 'Home' } },
    { path: 'office', data: { breadcrumb: 'Office' }, loadChildren: () => import('./office/office.module').then(mod => mod.OfficeModule) },
    { path: 'parts',  loadChildren: () => import('./parts/parts.module').then(mod => mod.PartsModule) }
  ]
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
