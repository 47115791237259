import { Component, OnInit, OnChanges, OnDestroy, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

export interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-electronic-document-delivery',
  templateUrl: './electronic-document-delivery.component.html',
  styleUrls: ['./electronic-document-delivery.component.css']
})
export class ElectronicDocumentDeliveryComponent implements OnInit  {

  // @HostListener('mouseover') onMouseOver() {
  //   let navbar = this.el.nativeElement.querySelector('outlet');
  // }

  selected = '';
  date;

  constructor(
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.selected = '';
   }

  ngOnInit() {
    
    let time= JSON.parse(localStorage.getItem('userObject')).suzukiUpdateTime;
    this.date = new Date(time);
  }

  onActivate(elementRef) {
    console.log("onActivate event");
    //this.selected = '';
}
onDeactivate(){
  console.log("onDeactivate event");
  this.selected = null;
}

  onTypeSelection(e):void {
    this.router.navigate(['office/electronic-document-delivery/'+ e]);
  }

  foods: Food[] = [
    {value: 'MonthlyPatsStatement', viewValue: 'Monthly Parts Statement'},
    {value: 'memos_search', viewValue: 'Debit & Credit Memos'},
    {value: 'partsInvoice', viewValue: 'Parts Invoices'},
    {value: 'tacos-2', viewValue: 'Vechicle Invoices'},
    {value: 'tacos-2', viewValue: 'Warranty Summary'},
    {value: 'tacos-2', viewValue: 'Holdback Statement'},
    {value: 'monthly-vechicle-statement', viewValue: 'Monthly Vechicle Statement'}
  ];

}
