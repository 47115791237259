import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchDocumentService } from 'src/app/services/search-document.service';

export interface PeriodicElement {
  invoice: number;
  order_no: string;
  invoice_process_date: string;
  amount: number;
}

// const ELEMENT_DATA: PeriodicElement[] = [
//   {
//     invoice: 14212151,
//     order_no: 'ROTHTUR',
//     invoice_process_date: '04/06/2018',
//     amount: 23
//   },
//   {
//     invoice: 14212151,
//     order_no: 'ROTHTUR',
//     invoice_process_date: '04/06/2018',
//     amount: 33
//   },
//   {
//     invoice: 14212151,
//     order_no: 'ROTHTUR',
//     invoice_process_date: '04/06/2018',
//     amount: 34
//   },
//   {
//     invoice: 14212151,
//     order_no: 'ROTHTUR',
//     invoice_process_date: '04/06/2018',
//     amount: 34
//   },
//   {
//     invoice: 14212151,
//     order_no: 'ROTHTUR',
//     invoice_process_date: '04/06/2018',
//     amount: 34
//   },
//   {
//     invoice: 14212151,
//     order_no: 'ROTHTUR',
//     invoice_process_date: '04/06/2018',
//     amount: 34
//   }
// ];

@Component({
  selector: 'app-memo-search-results',
  templateUrl: './memo-search-results.component.html',
  styleUrls: ['./memo-search-results.component.css']
})
export class MemoSearchResultsComponent implements OnInit {

  ELEMENT_DATA =[];
  dataSource;
  displayedColumns: string[] = [
    'createDate',
    'documentLink',
    'documentId'
  ];
  


  constructor(private searchDocument: SearchDocumentService) { 
    this.searchDocument
    .searchDocuments({
      dealerNumber: JSON.parse(localStorage.getItem('userObject')).suzukiDealerNumber,
      documentType: {
        documentJobName: 'EDD_AR_Statement'
      }
    })
    .subscribe((res:any) => {
      console.log(res);
      var temp = res
      temp.forEach(obj => {
        this.ELEMENT_DATA.push({
          documentId: obj.documentId,
          documentLink: 'View Document',
          createDate : new Date(obj.createDate)
        });
      });
      this.dataSource = this.ELEMENT_DATA;
    });
  }

  ngOnInit() {
    console.log(this.ELEMENT_DATA)
  }

  openWindow() {
    window.open('https://d3einiiox7l0e3.cloudfront.net/edd/vehicle_statement/9ab648c9-16a6-4766-94a1-6c5cb9e9c3b5.pdf', '_blank', 'top=50,left=50,width=600,heigh=500');
  }

}
