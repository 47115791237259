import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from './core/localStorageService/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
 
  constructor(
    private LocalStorageService : LocalStorageService,
    private router : Router 
  ){}

  canActivate(){
    if(this.LocalStorageService.loggedIn()){
      return true
    }else{
      this.router.navigate(['/login'])
      return false
    }
}
}
