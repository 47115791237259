import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ElectronicDocumentDeliveryComponent } from './electronic-document-delivery/electronic-document-delivery.component';
import { OfficeRoutingModule } from './office-routing.module';
import { MaterialModule } from '../material.module';
import { DebitAndCreditMemosComponent } from './SearchForms/debit-and-credit-memos/debit-and-credit-memos.component';
import { PartsInvoiceComponent } from './SearchForms/parts-invoice/parts-invoice.component';
import { InvoiceSearchResultsComponent } from './invoice-search-results/invoice-search-results.component';
import { SharedModule } from '../shared/shared.module';
import { MonthlyVechicleStatementComponent } from './SearchForms/monthly-vechicle-statement/monthly-vechicle-statement.component';
import { MemoSearchResultsComponent } from './memo-search-results/memo-search-results.component';
import { MonthlyPartsStatementComponent } from './SearchForms/monthly-parts-statement/monthly-parts-statement.component';




@NgModule({
  declarations: [
    ElectronicDocumentDeliveryComponent, 
    DebitAndCreditMemosComponent, 
    PartsInvoiceComponent, 
    InvoiceSearchResultsComponent,  
    MonthlyVechicleStatementComponent, 
    MemoSearchResultsComponent, 
    MonthlyPartsStatementComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OfficeRoutingModule,
    MaterialModule,
    SharedModule
    
  ]
})
export class OfficeModule { }
