import { Injectable } from '@angular/core';
import { LocalStorageService} from '../localStorageService/local-storage.service';


@Injectable({providedIn: 'root'})
export class CurrentTokenService {

  constructor(private LocalStorageService: LocalStorageService) {}

    tokenObj = this.init();

    public init () {
        let currentToken = {
            token: "",
            get loggedIn() {
                return this.token;
            }
        };
        let localToken: (object) = this.LocalStorageService.get('authToken');
        if (localToken) {
            currentToken.token = localToken['token'];
        }
        return currentToken;
    }


    public setToken(token: string) {
      this.tokenObj.token = token;
      this.LocalStorageService.add('authToken', this.tokenObj);
  }

    public deleteToken () {
        this.setToken(null);
        this.LocalStorageService.remove('authToken');
    };
}

